export default {
  apiUrl: 'https://amineb.auth0.com/api/v2/',
  apiUrl2: 'https://app.tradeui.com/wp-json/jwt-auth/v1/token/',
  apiUrl3: 'https://tradeui.com/api/login.php?token=',
};

function wsCfg() {
  var idto = localStorage.getItem('id_token');
  if (idto) {
    return 'wss://tradeui.com/ws?t=' + idto;
  } else {
    return 'wss://tradeui.com/ws?t=notoken';
  }
}
//TIMEZONE
const siteConfig = {
  siteName: 'TradeUI',
  siteIcon: 'ion-flash',
  footerText: '©2021-2025 Traderead Inc',
  TZoffset: -240,
  TZoffsetHour: -4,
  TZoffsetHourPosi: 4,
};

//hada
const jwtConfig = {
  fetchUrl2: 'https://tradeui.com/api/',
  fetchUrl: 'https://tradeui.com/wp-json/jwt-auth/v1/',
  fetchUrl3: 'https://tradeui.com/api/login.php?token=',
  secretKey: 'secretKey',
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'english';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: '',
};
const Auth0Config = {
  domain: 'amineb.auth0.com',
  clientID: 'DqdQKB2IeenYBMM5UHu88xpwt4RV1ogj',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: 'http://localhost:3335/auth0loginCallback',
    },
    languageDictionary: {
      title: 'TradeUI',
      emailInputPlaceholder: 'email...',
      passwordInputPlaceholder: 'password...',
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#7252e6',
      authButtons: {
        connectionName: {
          displayName: 'Sign in',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000',
        },
      },
    },
  },
};
// const firebaseConfig = {
//   apiKey: 'amino',
//   authDomain: '',
//   databaseURL: '',
//   projectId: '',
//   storageBucket: '',
//   messagingSenderId: '',
// };
const googleConfig = {
  apiKey: 'amino', //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: [],
};
const youtubeSearchApi = '';

const accountKitConfig = {
  appId: '',
  state: 'csrf',
  version: 'v1.0',
  fbAppEventsEnabled: true,
  redirect: '',
  //debug: process.env.NODE_ENV !== 'production',
};

export {
  //hena dial jwt
  jwtConfig,
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  wsCfg,
  // firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi,
  accountKitConfig,
};
