//import onClickUOBox from './optionflow';
import {
  GetSpeechLanguagePitch,
  GetSpeechLanguageSpeed,
  GetSpeechLanguageURI,
} from '../components/SpeechSettings/VoiceSettingsPersist';
import { siteConfig, wsCfg } from '../../settings';
import { store } from '../../redux/store';
import { setSWB, setUF } from '../../redux/flow/actions';
//import { upSoc } from '../../containers/App/ws';
// import moment from 'moment';
export const refreshRate = 4000;

var offsetpos = siteConfig.TZoffsetHourPosi;
var offsetneg = siteConfig.TZoffsetHour;
var offset = siteConfig.TZoffsetHourPosi;

export const returnoffsetDST = date => {
  var offset = offsetneg;
  const start = new Date(date.getFullYear(), 2, 14); // Second Sunday in March
  const end = new Date(date.getFullYear(), 10, 7); 
  start.setDate(14 - start.getDay());
  end.setDate(7 - end.getDay());
  if (date >= start && date < end) {
    let clientDate = new Date();
    let utc = clientDate.getTime() + clientDate.getTimezoneOffset() * 60000;
    offset = offsetneg;
  }
  return offset;
};

export function updateServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then(registrations => {
        for (let registration of registrations) {
          registration.update().then(() => {
            if (registration.waiting) {
              registration.waiting.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                  // Reload the page to use the updated service worker
                  window.location.reload();
                }
              });
              // Tell the service worker to skip waiting
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            } else {
              console.log('No service worker update found.');
            }
          });
        }
      })
      .catch(error => {
        console.error('Error during service worker update:', error);
      });
  }
}

export function notify_msg(symbol, message) {
  var img = 'https://app.tradeui.com/applogo.png';
  var notification = new Notification(`${symbol} Option Alert`, {
    body: message,
    icon: img,
  });
}
export function notify_news(symbol, message, image) {
  var img = 'https://app.tradeui.com/applogo.png';
  var notification = new Notification(`News: ${symbol} `, {
    body: message,
    image: image ? image : undefined,
    icon: img,
  });
  return notification;
}
export function notifyMeLib(symbol, message) {
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    notify_msg(symbol, message);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function(permission) {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        notify_msg(symbol, message);
      }
    });
  }
  // user denied leave alone
}
export function notifyMeNews(symbol, message, image) {
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    return notify_news(symbol, message, image);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function(permission) {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        return notify_news(symbol, message, image);
      }
    });
  }
  // user denied leave alone
}
export async function askUserPermission() {
  return await Notification.requestPermission();
}

export function numTComs(num) {
  if (num > 0) {
    num = '' + num;
    var num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return num_parts.join('.');
  } else {
    return 0;
  }
}
export function numTComs1(x) {
  //x = "" + x;
  //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return typeof x;
}
export function relDiff(a, b) {
  if (isNaN(+a) || isNaN(+b)) {
    console.log('<b class="warn">input error</b> (a=', a, ' b=', b, ')');
    return;
  }
  return a - b === 0 ? 0 : 100 * Math.abs((a - b) / b) || 'input error';
}

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export function moneyNess(put_call, spot, strike, description) {
  let mness = '';
  var calculatedSpot = spot == null ? calcTickerSpotPrice(description) : spot;

  if (typeof calculatedSpot === 'string') {
    calculatedSpot = calculatedSpot.replace(/\$/g, '');
  }

  if (put_call == 'CALL' || put_call == 'PUT') {
    if (calculatedSpot == strike) mness = 'ATM';
    if (put_call == 'PUT' && calculatedSpot > strike) {
      mness = 'OTM';
    } else if (put_call == 'PUT' && calculatedSpot < strike) {
      mness = 'ITM';
    }
    if (put_call == 'CALL' && strike > calculatedSpot) {
      mness = 'OTM';
    } else if (put_call == 'CALL' && calculatedSpot > strike) {
      mness = 'ITM';
    }
  }
  return mness;
}
export function calcPercMove(spot, strike) {
  var moveperc = '';
  if (spot !== undefined && strike !== undefined) {
    if (spot > 0) {
      if (spot && typeof spot === 'string' && spot.includes('$'))
        spot = spot.replace('$', '');
      // var spotNum = parseFloat(spot.replace('$', ''));
      var spotNum = spot;
      let move = (strike - spotNum) / spotNum;
      moveperc = Math.round(100 * 100 * move) / 100;
      moveperc = (moveperc >= 0 ? '+' : '') + moveperc + '%';
    } else {
      moveperc = 0;
    }
  }

  return moveperc;
}
export function removeDisabled() {
  document.querySelector(
    '#root > div > div > div > section > section > section > main > div > div > div:nth-child(4) > div > div.ReactTable.rTable.-striped.-highlight > div.pagination-top > div > div.-previous > button'
  ).disabled = false;
}

export function numbCommas(x) {
  let ret = x;
  if (x) {
    ret = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return ret;
}
export function kFormatter(num) {
  if (num === undefined) return num;
  if (Math.abs(num) > 999999999)
    return Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(1) + 'B';
  if (Math.abs(num) > 999999)
    return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'M';
  if (Math.abs(num) > 999)
    return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K';
  return num;
}

export function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

export function calcRating(totalOrderSize, openinterest) {
  if (totalOrderSize === undefined || openinterest === undefined) return 0;
  if (openinterest === 0) openinterest = 1;
  if (totalOrderSize === 0) totalOrderSize = 1;
  return Math.round((100 * totalOrderSize) / openinterest) / 100;
}
export function calcBoughtAt(price, bid, midpoint, ask) {
  if (
    price !== undefined &&
    bid !== undefined &&
    midpoint !== undefined &&
    ask !== undefined
  ) {
    price = parseFloat(price);
    bid = parseFloat(bid);
    midpoint = parseFloat(midpoint);
    ask = parseFloat(ask);
    var result = 'Midpoint';
    if (price >= (midpoint + ask) / 2.0) result = 'Ask';
    else if (price <= (bid + midpoint) / 2.0) result = 'Bid';

    return result; //+'(bid='+bid+';midpoint='+midpoint+';ask='+ask+')';
  } else {
    return '';
  }
}
function closest(bid, mid, ask, premium) {
  return [bid, mid, ask].reduce((a, b) => {
    let aDiff = Math.abs(a - premium);
    let bDiff = Math.abs(b - premium);

    if (aDiff === bDiff) {
      // Choose largest vs smallest (> vs <)
      return a > b ? a : b;
    } else {
      return bDiff < aDiff ? b : a;
    }
  });
}
export function calcBoughtAt2(price, bid, midpoint, ask, size, premium) {
  if (
    price !== undefined &&
    bid !== undefined &&
    midpoint !== undefined &&
    ask !== undefined
  ) {
    //price = parseFloat(price) * size * 100;
    let tbid = parseFloat(bid) * size * 100;
    let tmidpoint = parseFloat(midpoint) * size * 100;
    let task = parseFloat(ask) * size * 100;
    let result;
    var closez = closest(tbid, tmidpoint, task, premium);

    if (closez === tbid) {
      if (parseFloat(premium) > tbid) result = 'Above Bid';
      if (parseFloat(premium) === tbid) result = 'Bid';
      if (parseFloat(premium) < tbid) result = 'Below Bid';
    }
    if (closez === task) {
      if (parseFloat(premium) > task) result = 'Above Ask';
      if (parseFloat(premium) === task) result = 'Ask';
      if (parseFloat(premium) < task) result = 'Below Ask';
    }
    if (closez === tmidpoint) {
      if (parseFloat(premium) > tmidpoint) result = 'Above Mid';
      if (parseFloat(premium) === tmidpoint) result = 'Mid';
      if (parseFloat(premium) < tmidpoint) result = 'Below Mid';
    }

    // if ( closez = task ) result = 'Ask';
    // if ( closez = tmidpoint ) result = 'Mid';

    // if (price >= (midpoint + ask) / 2.0) result = 'Ask';
    // else if (price <= (bid + midpoint) / 2.0) result = 'Bid';
    return result; //+'(bid='+bid+';midpoint='+midpoint+';ask='+ask+')';
  } else {
    return '';
  }
}
//removed map fix
export function calcTickerSpotPrice(signalDescription) {
  if (signalDescription !== undefined) {
    var regex = /[+-]?\d+(\.\d+)?/g;
    var strspl = signalDescription.split('Ref=$');
    if (strspl && strspl[0] && strspl[1]) {
      var floats = strspl[1].match(regex);
      if (floats && floats.length > 0) {
        var x = '$' + parseFloat(floats[0]);
        if (x !== undefined) {
          return x;
        }
      }
    }
  }
  return null;
}
export function calcTickerSpotPrice_old(signalDescription) {
  if (signalDescription !== undefined) {
    //var xa = signalDescription.match(new RegExp('(Ref=(?<spotprice>.*))'));
    var regex = /[+-]?\d+(\.\d+)?/g;
    var strspl = signalDescription.split('Ref=$');
    if (strspl && strspl[0] && strspl[1]) {
      var floats = strspl[1].match(regex).map(function(v) {
        return parseFloat(v);
      });

      var x = '$' + floats[0];
      //   xa.groups != undefined &&
      //   xa.groups.spotprice != undefined
      if (x !== undefined) {
        return x;
      }
    } else {
      return 0;
    }
  }
  return null;
}

export function convertToInteger(v, defaultValueIfErr) {
  try {
    if (v !== undefined) {
      return parseInt(v);
    }
  } catch (err) {}
  return defaultValueIfErr;
}

export function convertToFloat(v, defaultValueIfErr) {
  try {
    if (v !== undefined) {
      return parseFloat(v);
    }
  } catch (err) {}
  return defaultValueIfErr;
}
export function padLeftZerosLen2(obj) {
  return padLeftZeros(obj, 2);
}
export function padLeftZeros(obj, length) {
  if (obj === undefined) return '';
  return obj.toString().padStart(length, '0');
}

export function addDays(date, nDays) {
  let dayMilliSecs = 24 * 60 * 60 * 1000;
  let x = new Date(date.getTime() + nDays * dayMilliSecs);
  return x;
}

export function convertUTCDateToLocalDate(date) {
  let timezoneOffsetMinutes = new Date().getTimezoneOffset();
  var offset = new Date().getTimezoneOffset();
  var date = new Date();
  date.setMinutes(date.getMinutes() + offset);

  var easternTimeOffset = siteConfig.TZoffset;
  date.setMinutes(date.getMinutes() + easternTimeOffset);
  //oldone:
  var newDate = new Date(date.getTime() - timezoneOffsetMinutes * 60 * 1000);
  //console.log(newDate)
  return date;
}
export function getDateCutToLastFriday(date) {
  if (date.getDay() === 6) return addDays(date, -1);
  if (date.getDay() === 0) return addDays(date, -2);
  return date;
}
export function getDateComingFriday(date) {
  if (date.getDay() === 6) return addDays(date, 5);
  if (date.getDay() < 6) return addDays(date, 5 - date.getDay());
  return date;
}
export function getCurrentDateLocal() {
  return convertUTCDateToLocalDate(new Date());
}
export function getCurrentDate() {
  return new Date();
}

export const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export function formatDateExpiration(date_expiration) {
  var now = new Date();
  if (date_expiration === undefined || date_expiration === '') return '';
  var date = new Date(date_expiration);
  var comingFriday = getDateComingFriday(getCurrentDate());
  if (
    date.getUTCDate() === comingFriday.getUTCDate() &&
    date.getUTCMonth() === comingFriday.getUTCMonth() &&
    date.getUTCFullYear() === comingFriday.getUTCFullYear()
  ) {
    return 'Fri';
  }
  let yy = '';
  if (now.getUTCFullYear() !== date.getUTCFullYear()) {
    yy = date
      .getUTCFullYear()
      .toString()
      .substr(-2);
  }
  return (
    capitalize(months[date.getUTCMonth()]) + ' ' + date.getUTCDate() + ' ' + yy
  );
}

// export function convertServerDateToLocal(dateInput) {
//   var offset = siteConfig.TZoffsetHourPosi;
//     console.log(offset)
//     serverDate = new Date(dateInput),
//     utc = serverDate.getTime() - serverDate.getTimezoneOffset() * 60000,

//     clientDate = new Date(utc + 3600000 * offset);
//   return clientDate.toLocaleString();
// }
//console.log(convertServerDateToLocal("2020-06-23 14:48:11"));
export function d_diff_indays(date1, date2) {
  let dt1 = new Date(date1);
  let dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
}
export function parseDateTZ(str_date) {
  let locale_date = new Date(Date.parse(str_date));

  return locale_date.toISOString().split('T')[0];
}
export function formatDateTimeLocal24(date, time) {
  if (date === undefined || time === undefined) return '';

  var dateFull = date + ' ' + time,
    serverDate = new Date(dateFull.replace(/-/g, '/')),
    utc = serverDate.getTime() - serverDate.getTimezoneOffset() * 60000,
    dt = new Date(utc + 3600000 * offset); // Ensure 'offset' is defined elsewhere in your code

  return (
    dt.getMonth() +
    1 +
    '/' +
    dt.getDate() +
    ' ' +
    padLeftZerosLen2(dt.getHours()) + // Use getHours() directly for 24-hour format
    ':' +
    padLeftZerosLen2(dt.getMinutes())
  );
}

export function formatDateTimeLocal(date, time) {
  if (date === undefined || time === undefined) return '';

  //timezone offset
  // remove this to fix .replace(/\s/, 'T')

  var dateFull = date + ' ' + time,
    serverDate = new Date(dateFull.replace(/-/g, '/')),
    utc = serverDate.getTime() - serverDate.getTimezoneOffset() * 60000,
    dt = new Date(utc + 3600000 * offset);
  var ampm = 'AM';
  if (dt.getHours() >= 12) ampm = 'PM';
  var hrs = dt.getHours() % 12;
  if (hrs === 0) hrs = 12;
  return (
    dt.getMonth() +
    1 +
    '/' +
    dt.getDate() +
    ' ' +
    padLeftZerosLen2(hrs) +
    ':' +
    padLeftZerosLen2(dt.getMinutes()) +
    ' ' +
    ampm
    // ':' +
    // padLeftZerosLen2(dt.getSeconds()) +
    // ' ' +
  );
}
export function formatDateTimeLocal3(datetime) {
  if (datetime === undefined) return '';
  //timezone offset
  var serverDate = new Date(datetime),
    utc = serverDate.getTime() - serverDate.getTimezoneOffset() * 60000,
    dt = new Date(utc + 3600000 * offset);
  var ampm = 'AM';
  if (dt.getHours() >= 12) ampm = 'PM';
  var hrs = dt.getHours() % 12;
  if (hrs === 0) hrs = 12;
  return (
    dt.getMonth() +
    1 +
    '/' +
    dt.getDate() +
    ' ' +
    padLeftZerosLen2(hrs) +
    ':' +
    padLeftZerosLen2(dt.getMinutes()) +
    ' ' +
    ampm
  );
}
export function formatDateTimeLocal2(datetime) {
  if (datetime === undefined) return '';
  //timezone offset a.b timechange
  //hada dert zero dial sweepsetups bach iweri eastern
  var offset = 0.0,
    serverDate = new Date(datetime),
    utc = serverDate.getTime() - serverDate.getTimezoneOffset() * 60000,
    dt = new Date(utc + 3600000 * offset);
  var ampm = 'AM';
  if (dt.getHours() >= 12) ampm = 'PM';
  var hrs = dt.getHours() % 12;
  if (hrs === 0) hrs = 12;
  return (
    padLeftZerosLen2(hrs) + ':' + padLeftZerosLen2(dt.getMinutes()) + ' ' + ampm
  );
}

export function getV(d, name) {
  let p = JSON.parse(d);
  console.log(p, name);
}

export function gapc(o, pc) {
  let dir = ((parseFloat(o) - parseFloat(pc)) / parseFloat(pc)) * 100;

  return dir.toFixed(2);
}
export function numComs(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function formatTimeLocal(date, time) {
  if (date === undefined || time === undefined) return '';
  var dtstr = date + 'T' + time + '+00:00'; //setting UTC date

  var dt = new Date(dtstr);
  var ampm = 'AM';
  if (dt.getHours() >= 12) ampm = 'PM';
  var hrs = dt.getHours() % 12;
  if (hrs === 0) hrs = 12;
  return (
    padLeftZerosLen2(hrs) + ':' + padLeftZerosLen2(dt.getMinutes()) + ' ' + ampm
  );
}

export function redrawFut(repo) {
  document.getElementById('fupdown').innerHTML = '';
  let fupd = document.getElementById('fupdown');
  let ell,
    selectc = document.getElementById('mstretch');

  let futures = [];
  repo.forEach(e => {
    ell = e.ticker.split(':')[1].replace('=F', '');
    futures[ell] = e;
  });
  Object.entries(futures).map(([key, tickerObj]) => {
    let tickr = key;
    let upd = '';
    let arrUP = '<i class="fireup dotx"></i>';
    let arrDo = '<i class="firedown dotx"></i>';
    // fupd.insertAdjacentHTML('beforeend', `${tickr}:`);
    Object.entries(tickerObj).map(([key, item]) => {
      if (key != 'ticker' && key != 'timestamp') {
        if (parseFloat(item) > 0) {
          upd += `<span title='${key} -> ${parseFloat(item)}'>${arrUP}</span>`;
        }
        if (parseFloat(item) < 0) {
          upd += `<span title='${key} -> ${parseFloat(item)}'>${arrDo}</span>`;
        }
        if (parseFloat(item) == 0) {
          upd += `<span title='${key} -> ${parseFloat(item)}'>⚫</span>`;
        }
      }
      //let upt = `<i class="ion-arrow-${upd}-a fire${upd}"></i>`
      //fupd.insertAdjacentHTML('beforeend', upt);
    });
    if (tickr == 'ES') {
      selectc.options[0].value = `${upd}`;
      document.getElementById('arrS').innerHTML = `${upd}`;
      selectc.options[0].innerHTML = `/${tickr}`;
    }
    if (tickr == 'NQ') {
      selectc.options[1].value = `${upd}`;
      selectc.options[1].innerHTML = `/${tickr}`;
    }
    if (tickr == 'YM') {
      selectc.options[2].value = `${upd}`;
      selectc.options[2].innerHTML = `/${tickr}`;
    }
    if (tickr == 'GC') {
      selectc.options[3].value = `${upd}`;
      selectc.options[3].innerHTML = `/${tickr}`;
    }
    if (tickr == 'CL') {
      selectc.options[4].value = `${upd}`;
      selectc.options[4].innerHTML = `/${tickr}`;
    }
    if (tickr == 'BTC') {
      selectc.options[5].value = `${upd}`;
      selectc.options[5].innerHTML = `/${tickr}`;
    }
    // fupd.insertAdjacentHTML('beforeend', upd);
  });
}
export function drawSWB(repo) {
  if (repo && repo.resultat) {
    let vs = Object.values(repo.resultat);
    store.dispatch(setSWB(vs));
  }
}

export function drawUOBox(repo) {
  if (repo && repo.length > 0) {
    store.dispatch(setUF(Object.values(repo)));
  }
}

export function OSfrm(res) {
  //console.clear();
  let finalChain = [];
  const allC = Object.values(res).filter(w => w.put_call == 'CALL');
  const allP = Object.values(res).filter(w => w.put_call == 'PUT');
  let finalChainC = retOSO(allC);
  let finalChainP = retOSO(allP);
  finalChainC.forEach(e => {
    finalChain.push(e);
  });
  finalChainP.forEach(e => {
    finalChain.push(e);
  });

  return finalChain;
}
function retOSO(ret) {
  let len = ret.length;
  let finalChain = [];
  let fItemV = {};
  let fItemOI = {};
  ret.forEach((val, i) => {
    if (i == 0) {
      fItemV = {};
      fItemOI = {};
      fItemOI.data = [];
      fItemV.data = [];
    }
    fItemV.name = val.put_call + ' Contracts';
    if (val.put_call == 'PUT') {
      fItemV.color = '#ff2f64';
      fItemOI.color = '#bc68aa';
    }
    if (val.put_call == 'CALL') {
      fItemV.color = '#00b399';
      fItemOI.color = '#7252e6';
    }
    fItemV.stack = val.put_call;
    //console.log(val.size,":", val.open_interest)
    fItemV.data.push(parseInt(val.size));
    fItemOI.name = val.put_call + ' Open.Interest';
    fItemOI.stack = val.put_call;
    fItemOI.data.push(parseInt(val.trade_count));
    if (i == len - 1) {
      finalChain.push(fItemV);
      finalChain.push(fItemOI);
    }
  });
  return finalChain;
}
export function frm(expir, res) {
  let rputs = res.putExpDateMap[expir];
  let rcalls = res.callExpDateMap[expir];
  let finalChain = [];
  let finalChainC = retOCO(Object.values(rcalls));
  let finalChainP = retOCO(Object.values(rputs));
  finalChainC.forEach(e => {
    finalChain.push(e);
  });
  finalChainP.forEach(e => {
    finalChain.push(e);
  });
  return finalChain;
}
function retOCO(ret) {
  let len = ret.length;
  let finalChain = [];
  let fItemV = {};
  let fItemOI = {};
  ret.forEach((val, i) => {
    if (i == 0) {
      fItemV = {};
      fItemOI = {};
      fItemOI.data = [];
      fItemV.data = [];
    }
    fItemV.name = val[0].putCall + ' Volume';
    if (val[0].putCall == 'PUT') {
      fItemV.color = '#ff2f64';
      fItemOI.color = '#bc68aa';
    } else {
      fItemV.color = '#00b399';
      fItemOI.color = '#7252e6';
    }
    fItemV.stack = val[0].putCall;
    fItemV.data.push(val[0].totalVolume);
    fItemOI.name = val[0].putCall + ' Open.Interest';

    fItemOI.stack = val[0].putCall;
    fItemOI.data.push(val[0].openInterest);
    if (i == len - 1) {
      finalChain.push(fItemV);
      finalChain.push(fItemOI);
    }
  });
  return finalChain;
}

export function initWS() {
  window.con = false;
  const connection = new WebSocket(wsCfg());

  connection.onopen = () => {
    window.con = true;
    let payload = { type: 'init', payload: 1 };
    connection.send(JSON.stringify(payload));
  };

  connection.onclose = either => {
    // console.error('disconnected', either);
    window.con = false;
    setTimeout(() => {
      initWS();
    }, 5000);
  };

  connection.onerror = error => {
    window.con = false;
    console.error('failed to connect', error);
  };
  var lastSScnt = 0;
  connection.onmessage = event => {
    //  console.log(event)
    if (event.data) {
      try {
        var repo = JSON.parse(event.data);

        if (typeof repo == 'object') {
          if (repo.futures) {
            window.futures = repo.futures;
          }
          if (repo.type === 'er') {
            sessionStorage.setItem('er', JSON.stringify(repo.resultat));
          } else if (repo['type'] === 's_futures' && repo['resultat']) {
            redrawFut(repo.resultat);
          } else if (repo.type === 's_flg' && repo.resultat) {
            if (lastSScnt != repo.resultat.length) {
              drawSWB(repo);
            }
            lastSScnt = repo.resultat.length;
          } else if (repo.type === 'SWB') {
            sessionStorage.setItem('SWB', JSON.stringify(repo.resultat));
          } else if (repo && !repo.type) {
            drawUOBox(repo);
          } else if (repo.type === 'handshake') {
            // if (localStorage.getItem('tui_v') != repo.payload) {
            //   localStorage.setItem('tui_v', repo.payload);
            //   localStorage.setItem('tui_u', '1');
            // }
            // if (repo.status) {
            //   if (repo.status == 'c') {
            //     if (document.getElementById('timeto'))
            //       document.getElementById('timeto').style.display = 'block';
            //     document.getElementById('timetoO').style.display = 'none';
            //   }
            //   if (repo.status == 'o') {
            //     if (document.getElementById('timetoO'))
            //       document.getElementById('timetoO').style.display = 'block';
            //     document.getElementById('timeto').style.display = 'none';
            //   }
            // }
            //localStorage.getItem('tui_v') != repo.payload ? localStorage.setItem('tui_v',repo.payload) && localStorage.setItem('tui_u',"1") : console.log(1)
          }
        }
      } catch (error) {
        // /console.log("err: " , error)
      }
    }
  };

  window.c = connection;
  return window.con;
}
export function IWSTop() {
  /* window.con = false;
  const connection = new WebSocket(wsCfg);

  connection.onopen = () => {
    window.con = true;
    let payload = { type: 'init', payload: 1 };
    connection.send(JSON.stringify(payload));
  };
  connection.onclose = either => {
    // console.error('disconnected', either);
    window.con = false;
  };

  connection.onerror = error => {
    window.con = false;
    console.error('failed to connect', error);
  };
  var lastSScnt = 0;
  connection.onmessage = event => {
    //  console.log(event)
    if (event.data) {
      try {
        var repo = JSON.parse(event.data);
        if (repo.social) {
          upSoc(repo.social);
         // connection.close();
        }
      } catch (error) {
        // /console.log("err: " , error)
      }
    }
  };

  window.d = connection;
  return window.con; */
}
export function is_weekend(date1) {
  var dt = new Date(date1);

  if (dt.getDay() === 6 || dt.getDay() === 5) {
    return true;
  }
}
// export function updb(optionD) {
//   var db;
//   var request = indexedDB.open('TUI', 1);
//   request.onerror = function(event) {
//     console.log("didn't you allow to use IndexedDB?!");
//   };
//   request.onsuccess = function(event) {
//     db = event.target.result;
//     var d = new Date();
//     let tod = d.yyyymmdd();
//     let transaction = db.transaction('List', 'readwrite');

//     let oList = transaction.objectStore('List');
//     var getR = oList.get(tod);

//     getR.onerror = function(event) {
//       alert('Unable to retrieve frm db');
//     };

//     getR.onsuccess = function(event) {
//       if (getR.result) {
//         let lIndex = getR.result.data[getR.result.data.length - 1].oid;
//         //console.log(lIndex);
//         localStorage.setItem('lI', lIndex);
//       } else {
//       }
//     };

//     oList.delete(tod);
//     let oneList = {
//       id: tod,
//       created: new Date(),
//       data: optionD,
//     };

//     let addRequest = oList.add(oneList);

//     addRequest.onsuccess = function() {
//       console.log('added to the store', addRequest.result);
//     };

//     addRequest.onerror = function() {
//       console.log('Error', addRequest.error);
//     };
//   };

// }
//hado dial stadi jdida

export function getP(pricePoint, price, strike, type, strategy) {
  let profit;
  switch (type) {
    case 'PUT':
      profit = strike - price - pricePoint;
      break;
    case 'CALL':
      profit = pricePoint - strike - price;
      break;
    case 'straddle':
      if (pricePoint >= strike) profit = pricePoint - strike + price;
      else profit = strike - price - pricePoint;
      break;
    //no default
  }
  if (
    (strategy === 'single' &&
      Math.abs(profit) > parseFloat(price) &&
      parseFloat(profit) < 0) ||
    Number.isNaN(profit)
  ) {
    profit = -price;
  }

  return profit.toLocaleString(undefined, { maximumFractionDigits: 2 });
}
// function BlackScholes(PutCallFlag, S, X, T, r, v) {
//   var d1 = (Math.log(S / X) + (r + (v * v) / 2) * T) / (v * Math.sqrt(T));
//   var d2 = d1 - v * Math.sqrt(T);
//   if (PutCallFlag === 'call' || PutCallFlag === 'CALL') {
//     return S * CND(d1) - X * Math.exp(-r * T) * CND(d2);
//   } else {
//     return X * Math.exp(-r * T) * CND(-d2) - S * CND(-d1);
//   }
// }

/* The cummulative Normal distribution function: */
// function CND(x) {
//   let k;
//   if (x < 0) {
//     return 1 - CND(-x);
//   } else {
//     k = 1 / (1 + 0.2316419 * x);
//     return (
//       1 -
//       (Math.exp((-x * x) / 2) / Math.sqrt(2 * Math.PI)) *
//         k *
//         (0.31938153 +
//           k *
//             (-0.356563782 +
//               k * (1.781477937 + k * (-1.821255978 + k * 1.330274429))))
//     );
//   }
// }
export function getBS(pricePoint, price, strike, type, strategy, vol, time) {
  // console.log(pricePoint, price, strike, type, strategy, vol, time);
  //let r = 0.01;
  // let r = 0.1348;
  // vol = 32.79;
  // let v = vol / 100;
  // let T = time / 365;
  // let S = pricePoint;
  // let ret = BlackScholes(type, S, strike, T, r, v);
  // console.log("Blackscholes values: ",type, S, strike, T, r, v);
  // console.log('B.S: ' + ret);
  return 0;
}
export function snd(t) {
  var audio = new Audio('https://tradeui.com/tuison.wav');
  audio.play();
}

export const selectVoice = voiceURI => {
  var s = window.speechSynthesis.getVoices();
  const vc = s.find(voice => voice.voiceURI === voiceURI);

  return vc;
};
export function spx(t) {
  var s = window.speechSynthesis;
  s.cancel();
  let ut = new SpeechSynthesisUtterance(t);
  ut.voice = selectVoice(GetSpeechLanguageURI());
  ut.pitch = GetSpeechLanguagePitch();
  ut.rate = GetSpeechLanguageSpeed();
  s.speak(ut);
}

export function ufn(type, v) {
  let srl = 'http://topicfood.com/s/';
  //ternary or case
  var audio = new Audio(srl + 'unusualflow.mp3');
  audio.play();
}
export function isLSec(dbdate) {
  let datee = new Date(dbdate);
  let isoDate = new Date().toISOString();
  let F_MIN = 5 * 60 * 1000;
  let T_S = 10 * 1000;
  let F_D = 5 * 60 * 24 * 60 * 1000;
  // console.log('date iso:', isoDate);
  // console.log('datee :', datee);
  // console.log('minus :', isoDate - datee);
  if (isoDate - datee < T_S) {
    return true;
  } else {
    return false;
  }
}

export function isMarketOpen() {
  let offset1 = offsetneg;
  let clientDate = new Date();
  let utc = clientDate.getTime() + clientDate.getTimezoneOffset() * 60000;
  let serverDate = new Date(utc + 3600000 * offset1);
  let estStr = serverDate;

  let now = estStr.getHours();
  let nowMin = estStr.getMinutes();
  let nowDay = estStr.getDay();
  let marketIS = false;
  if (now >= 9 && now < 16) {
    if (nowDay != 0 && nowDay != 6) {
      marketIS = true;
    }
    if (now == 9 && nowMin < 30) {
      marketIS = false;
    }
  }
  return marketIS;
}
export function isBmo() {
  let offset1 =  offsetneg;
  let clientDate = new Date();
  let utc = clientDate.getTime() + clientDate.getTimezoneOffset() * 60000;
  let serverDate = new Date(utc + 3600000 * offset1);
  let estStr = serverDate;

  let now = estStr.getHours();
  let nowMin = estStr.getMinutes();
  let nowDay = estStr.getDay();
  let isBmo = false;
  if (now < 9) {
    isBmo = true;
  }
  return isBmo;
}
export function isAmc() {
  let offset1 = offsetneg;
  let clientDate = new Date();
  let utc = clientDate.getTime() + clientDate.getTimezoneOffset() * 60000;
  let serverDate = new Date(utc + 3600000 * offset1);
  let estStr = serverDate;

  let now = estStr.getHours();
  let nowMin = estStr.getMinutes();
  let nowDay = estStr.getDay();
  let isAmc = false;
  if (now > 16) {
    isAmc = true;
  }
  return isAmc;
}
export function isWeekend() {
  let offset1 = offsetneg;
  let clientDate = new Date();
  let utc = clientDate.getTime() + clientDate.getTimezoneOffset() * 60000;
  let serverDate = new Date(utc + 3600000 * offset1);
  let estStr = serverDate;

  let nowDay = estStr.getDay();
  let isWeekend = false;
  if (nowDay === 0 || nowDay === 6) {
    isWeekend = true;
  }
  return isWeekend;
}

export const setNotice = (show, message) => {
  let notice = document.getElementById('noticeDiv');
  let noticeTxt = document.getElementById('noticeTxt');
  if (show === true) {
    noticeTxt.innerText = message;
    notice.style.display = 'block';
  } else {
    notice.style.display = 'none';
  }
};
